import { BuildingSearchInput } from "gqlTypes";
import { LocalizationKey } from "Localization/types";

export type GetElementType<T extends Array<any>> = T extends (infer U)[] ? U : never;

export type Dimensions = {
  width: number;
  height: number;
};

export type LatLngType = {
  lat: number;
  lng: number;
};

export enum FilterTypes {
  Type = "TYPE",
  Bedrooms = "BEDROOMS",
  Availability = "AVAILABILITY",
  Cities = "CITIES",
  DataSources = "DATA_SOURCES",
  Visibilities = "VISIBILITIES",
  Amenities = "AMENITIES",
  Published = "PUBLISHED",
  Verified = "VERIFIED",
  Organizations = "ORGANIZATIONS",
  Tags = "TAGS",
}

export interface FilterOption {
  value: string;
  display: string;
  disabled?: boolean;
}

export interface FilterDefinition {
  title: string;
  key: FilterTypes;
  value: any;
  multi?: boolean;
  reduce: (val: any) => BuildingSearchInput;
  options: FilterOption[];
}

export enum GtmTriggers {
  ExternalLinkClick = "gtm-external-link-click",
  ExternalLinkCancel = "gtm-external-link-cancel",
  ExternalLinkOffsite = "gtm-external-link-offsite",
  GlossaryInfoIcon = "gtm-glossary-info-icon-link",
  FilterReset = "gtm-filter-reset",
  FilterChange = "gtm-filter-change",
  NoResultsFound = "gtm-search-no-results-found",
  ZeroResults = "gtm-search-zero-results",
  ShareClick = "gtm-share-click",
  ShareMenuClick = "gtm-open-share-menu",
  SearchLocation = "gtm-search-location",
  BuildingCardClick = "gtm-building-card-click",
  ListingExpandCollapse = "gtm-listing-expand-collapse",
  ListingPhotos = "gtm-listing-photos-click",
  ListingMap = "gtm-listing-map-click",
  ListingLink = "gtm-listing-link-click",
  ListingSectionVisible = "gtm-listing-section-visible",
  PhotoGalleryOpen = "gtm-photo-gallery-open",
  PhotoGalleryClosed = "gtm-photo-gallery-closed",
  PhotoGalleryNavigate = "gtm-photo-gallery-navigate",
  HeaderNavLink = "gtm-header-nav-link-click",
  HeaderNavMenu = "gtm-header-nav-menu-click",
  FooterNavLink = "gtm-footer-nav-link-click",
  LandingCardClick = "gtm-landing-card-click",
  LandingCardBrowseClick = "gtm-landing-card-browse-click",
  BrowserPrint = "gtm-browser-print",
  PrintSearchResults = "gtm-print-search-results",
  GoogleTranslate = "gtm-google-translate",
  SaveListing = "gtm-save-listing",
  PublishListing = "gtm-publish-listing",
  Login = "gtm-login",
  None = "gtm-none",
}

export enum GtmActions {
  AddBedroomFilter = "Add Bedroom Filter",
  RemoveBedroomFilter = "Remove Bedroom Filter",
  AddAccessibilityFilter = "Add Accessibility Filter",
  RemoveAccessibilityFilter = "Remove Accessibility Filter",
  AddAmenityFilter = "Add Amenity Filter",
  RemoveAmenityFilter = "Remove Amenity Filter",
  AddOptionsFilter = "Add Options Filter",
  RemoveOptionsFilter = "Remove Options Filter",
  AddEligibilityFilter = "Add Eligibility Filter",
  RemoveEligibilityFilter = "Remove Eligibility Filter",
  AddAffordabilityFilter = "Add Affordability Filter",
  RemoveAffordabilityFilter = "Remove Affordability Filter",
  AddMoreFilter = "Add More Filter",
  RemoveMoreFilter = "Remove More Filter",
  AddAvailabilityFilter = "Add Availability Filter",
  RemoveAvailabilityFilter = "Remove Availability Filter",
  ClearFilterSet = "Clear Filter Set",
  ShareSearch = "Share Search",
  ShareListing = "Share Listing",
  PrintSearchResults = "Print Search Results",
  SearchLocationLanding = "landing",
  SearchLocationFilters = "search filters",
  SearchLocationOverlay = "search overlay",
  BuildingCardMap = "Map View",
  BuildingCardListing = "List View",
  ListingExpand = "Expand",
  ListingCollapse = "Collapse",
  LandingCardAvailableSoon = "Available Soon Card Click",
  LandingCardFirstComeFirstServed = "First Come, First Served Card Click",
  LandingCardShortWaitlists = "Short Waitlists Card Click",
  LandingCardMostAffordable = "Most Affordable Card Click",
  LandingCardResources = "Resources Card Click",
  None = "None",
}

export enum GtmLabels {
  ExplainFilters = "EXPLAIN_FILTERS",
  AgeRestrictions = "AGE_RESTRICTIONS",
  Verified = "VERIFIED",
  VerifiedFilter = "VERIFIED_FILTER",
  PetFriendly = "PET_FRIENDLY",
  RentBasedOnIncome = "RENT_BASED_ON_INCOME",
  BelowMarketRent = "FIXED_BELOW_MARKET_RENT",
  WaitlistOpen = "WAITLIST_OPEN",
  WaitlistClosed = "WAITLIST_CLOSED",
  ShortWaitlist = "SHORT_WAITLIST",
  WaitlistUnknown = "WAITLIST_UNKNOWN",
  WaitlistWebsiteLink = "WAITLIST_WEBSITE_LINK",
  WaitlistApplicationLink = "WAITLIST_APPLICATION_LINK",
  SectionWebsiteLink = "SECTION_WEBSITE_LINK",
  SectionApplicationLink = "SECTION_APPLICATION_LINK",
  MobileWebsiteLink = "MOBILE_WEBSITE_LINK",
  EmailAddressLink = "EMAIL_ADDRESS_LINK",
  PhoneNumberLink = "PHONE_NUMBER_LINK",
  AmenityFilter = "AMENITY_FILTER",
  AnyBedrooms = "ANY_BEDROOMS",
  Mobility = "MOBILITY",
  Vision = "VISION",
  Hearing = "HEARING",
  Studio = "STUDIO",
  Sro = "SRO",
  OneBr = "ONE_BR",
  TwoBr = "TWO_BR",
  ThreeBr = "THREE_BR",
  FourPlusBr = "FOUR_PLUS_BR",
  AgeRestrictedOrDisabled = "AGE_RESTRICTED_OR_DISABLED",
  AgeRestricted = "AGE_RESTRICTED",
  NotAgeRestricted = "NOT_AGE_RESTRICTED",
  AgeRestriction = "AGE_RESTRICTION",
  Lotteries = "LOTTERIES",
  FirstComeFirstServed = "FIRST_COME_FIRST_SERVED",
  ShowEverything = "SHOW_EVERYTHING",
  MobileResetSearchFilters = "MOBILE_RESET_SEARCH_FILTERS",
  DesktopResetSearchFilters = "DESKTOP_RESET_SEARCH_FILTERS",
  NoResultsReset = "NO_RESULTS_RESET",
  NoResultsExpand = "NO_RESULTS_EXPAND",
  NoResults = "NO_RESULTS",
  ZeroResults = "ZERO_RESULTS",
  TrueZeroResults = "TRUE_ZERO_RESULTS",
  ShareEmail = "SHARE_EMAIL",
  ShareCopy = "SHARE_COPY",
  ShareText = "SHARE_TEXT",
  PrintSearchResults = "PRINT_SEARCH_RESULTS",
  ShareMenuDesktopSearch = "SHARE_MENU_DESKTOP_SEARCH",
  ShareMenuDesktopListing = "SHARE_MENU_DESKTOP_LISTING",
  ShareMenuMobileSearch = "SHARE_MENU_MOBILE_SEARCH",
  ShareMenuMobileListing = "SHARE_MENU_MOBILE_LISTING",
  SearchLocation = "SEARCH_LOCATION",
  BuildingCardClick = "BUILDING_CARD_CLICK",
  NearbyBuildingCardClick = "NEARBY_BUILDING_CARD_CLICK",
  ListingSectionTitle = "LISTING_SECTION_TITLE",
  ListingPhotos = "LISTING_PHOTOS",
  ListingMapDirections = "LISTING_MAP_DIRECTIONS",
  ListingMapStreetView = "LISTING_MAP_STREET_VIEW",
  ListingLink = "LISTING_LINK",
  ListingSectionVisible = "LISTING_SECTION_VISIBLE",
  PhotoGalleryOpen = "PHOTO_GALLERY_OPEN",
  HeaderNavMenuMobile = "HEADER_NAV_MENU_MOBILE",
  HeaderNavLinkFindHousing = "HEADER_LINK_FIND_HOUSING",
  HeaderNavLinkAbout = "HEADER_LINK_ABOUT",
  HeaderNavLinkResources = "HEADER_LINK_RESOURCES",
  HeaderNavMenuForOwners = "HEADER_MENU_FOR_OWNERS",
  HeaderNavLinkForOwners = "HEADER_LINK_FOR_OWNERS",
  HeaderNavLinkForOwnersWelcome = "HEADER_LINK_FOR_OWNERS_WELCOME",
  HeaderNavLinkForOwnersSignIn = "HEADER_LINK_FOR_OWNERS_SIGN_IN",
  HeaderNavLinkForOwnersCreateAccount = "HEADER_LINK_FOR_OWNERS_CREATE_ACCOUNT",
  HeaderNavMenuForAdmins = "HEADER_LINK_FOR_ADMINS",
  HeaderNavLinkAdminHome = "HEADER_LINK_ADMIN_HOME",
  HeaderNavLinkAdminRecordSearch = "HEADER_LINK_ADMIN_RECORD_SEARCH",
  HeaderNavLinkAdminEditUsers = "HEADER_LINK_ADMIN_EDIT_USERS",
  HeaderNavLinkAdminEditOrganizations = "HEADER_LINK_ADMIN_EDIT_ORGANIZATIONS",
  HeaderNavLinkAdminRecordImports = "HEADER_LINK_ADMIN_IMPORTS",
  HeaderNavLinkAdminUtilities = "HEADER_LINK_ADMIN_UTILITIES",
  HeaderNavMenuAccount = "HEADER_LINK_ACCOUNT",
  HeaderNavLinkMyAccount = "HEADER_LINK_MY_ACCOUNT",
  HeaderNavLinkLogOut = "HEADER_LINK_LOG_OUT",
  HeaderNavLinkHome = "HEADER_LINK_HOME",
  FooterNavLinkTermsOfService = "FOOTER_LINK_TERMS_OF_SERVICE",
  FooterNavLinkPrivacyPolicy = "FOOTER_LINK_PRIVACY_POLICY",
  FooterNavLinkAccessibilityPolicy = "FOOTER_LINK_ACCESSIBILITY_POLICY",
  FooterNavLinkBackToTop = "FOOTER_LINK_BACK_TO_TOP",
  LandingCardAvailableSoon = "LANDING_CARD_AVAILABLE_SOON",
  LandingCardAvailableSoonBuilding = "LANDING_CARD_AVAILABLE_SOON_BUILDING",
  LandingCardFirstComeFirstServed = "LANDING_CARD_FCFS",
  LandingCardShortWaitlists = "LANDING_CARD_SHORT_WAITLISTS",
  LandingCardMostAffordable = "LANDING_CARD_MOST_AFFORDABLE",
  LandingCardResources = "LANDING_CARD_RESOURCES",
  FilterSetAvailability = "AVAILABILITY_FILTER_SET",
  FilterSetBedrooms = "BEDROOMS_FILTER_SET",
  FilterSetEligibility = "ELIGIBILITY_FILTER_SET",
  FilterSetAffordability = "AFFORDABILITY_FILTER_SET",
  FilterSetMore = "MORE_FILTER_SET",
  None = "none",
}

export type GtmLabel = keyof typeof GtmLabels;
export type GtmTrigger = keyof typeof GtmTriggers;
export type GtmLabelGroup = Record<GtmLabels, LocalizationKey>;

export enum DocumentCategory {
  Brochure = "brochure",
  Application = "application",
}

export const isDocumentCategory = (input: any): input is DocumentCategory =>
  Object.values(DocumentCategory).some((c) => c === input);

export const GTM_DEFAULT_LABELS: GtmLabelGroup = {
  [GtmLabels.ExplainFilters]: "gtm.none",
  [GtmLabels.AgeRestrictions]: "gtm.none",
  [GtmLabels.Verified]: "gtm.none",
  [GtmLabels.VerifiedFilter]: "filters.options.verified",
  [GtmLabels.PetFriendly]: "filters.options.petFriendly",
  [GtmLabels.RentBasedOnIncome]: "gtm.none",
  [GtmLabels.BelowMarketRent]: "gtm.none",
  [GtmLabels.WaitlistOpen]: "gtm.none",
  [GtmLabels.WaitlistClosed]: "gtm.none",
  [GtmLabels.WaitlistUnknown]: "gtm.none",
  [GtmLabels.ShortWaitlist]: "filters.availability.shortWaitlist",
  [GtmLabels.WaitlistWebsiteLink]: "gtm.none",
  [GtmLabels.WaitlistApplicationLink]: "gtm.none",
  [GtmLabels.SectionWebsiteLink]: "gtm.none",
  [GtmLabels.SectionApplicationLink]: "gtm.none",
  [GtmLabels.MobileWebsiteLink]: "gtm.none",
  [GtmLabels.EmailAddressLink]: "gtm.none",
  [GtmLabels.PhoneNumberLink]: "gtm.none",
  [GtmLabels.AmenityFilter]: "gtm.none",
  [GtmLabels.AnyBedrooms]: "filters.bedrooms.anyBedrooms",
  [GtmLabels.Mobility]: "unitAccessibility.mobility",
  [GtmLabels.Vision]: "unitAccessibility.vision",
  [GtmLabels.Hearing]: "unitAccessibility.hearing",
  [GtmLabels.Studio]: "bedrooms.studio",
  [GtmLabels.Sro]: "bedrooms.sro",
  [GtmLabels.OneBr]: "bedrooms.1br",
  [GtmLabels.TwoBr]: "bedrooms.2br",
  [GtmLabels.ThreeBr]: "bedrooms.3br",
  [GtmLabels.FourPlusBr]: "bedrooms.4+br",
  [GtmLabels.AgeRestrictedOrDisabled]: "filters.options.ageRestrictedOrDisabled",
  [GtmLabels.AgeRestricted]: "filters.options.ageRestricted",
  [GtmLabels.NotAgeRestricted]: "filters.options.notAgeRestricted",
  [GtmLabels.AgeRestriction]: "filters.options.ageRestriction",
  [GtmLabels.Lotteries]: "filters.availability.lotteries",
  [GtmLabels.FirstComeFirstServed]: "filters.availability.fcfs",
  [GtmLabels.ShowEverything]: "filters.availability.all",
  [GtmLabels.MobileResetSearchFilters]: "gtm.mobileResetSearchFilters",
  [GtmLabels.DesktopResetSearchFilters]: "gtm.desktopResetSearchFilters",
  [GtmLabels.NoResultsReset]: "resultsList.resetFilters",
  [GtmLabels.NoResultsExpand]: "resultsList.expandSearchArea",
  [GtmLabels.NoResults]: "gtm.noResults",
  [GtmLabels.ZeroResults]: "gtm.zeroResults",
  [GtmLabels.TrueZeroResults]: "gtm.trueZeroResults",
  [GtmLabels.ShareEmail]: "gtm.shareEmail",
  [GtmLabels.ShareCopy]: "gtm.shareCopy",
  [GtmLabels.ShareText]: "gtm.shareText",
  [GtmLabels.PrintSearchResults]: "gtm.sharePrintResults",
  [GtmLabels.ShareMenuDesktopSearch]: "gtm.shareMenuDesktopSearch",
  [GtmLabels.ShareMenuDesktopListing]: "gtm.shareMenuDesktopListing",
  [GtmLabels.ShareMenuMobileSearch]: "gtm.shareMenuMobileSearch",
  [GtmLabels.ShareMenuMobileListing]: "gtm.shareMenuMobileListing",
  [GtmLabels.SearchLocation]: "gtm.searchLocation",
  [GtmLabels.BuildingCardClick]: "gtm.buildingCardClick",
  [GtmLabels.NearbyBuildingCardClick]: "gtm.nearbyBuildingCardClick",
  [GtmLabels.ListingSectionTitle]: "gtm.listingSectionTitle",
  [GtmLabels.ListingPhotos]: "gtm.listingPhotos",
  [GtmLabels.ListingMapDirections]: "listing.map.directions",
  [GtmLabels.ListingMapStreetView]: "listing.map.streetView",
  [GtmLabels.ListingLink]: "gtm.listingLink",
  [GtmLabels.ListingSectionVisible]: "gtm.listingSectionVisible",
  [GtmLabels.PhotoGalleryOpen]: "gtm.photoGalleryOpen",
  [GtmLabels.HeaderNavMenuMobile]: "navigation.mobile",
  [GtmLabels.HeaderNavLinkFindHousing]: "navigation.navigator",
  [GtmLabels.HeaderNavLinkAbout]: "navigation.about",
  [GtmLabels.HeaderNavLinkResources]: "navigation.resources",
  [GtmLabels.HeaderNavMenuForOwners]: "navigation.forOwnersAndManagers",
  [GtmLabels.HeaderNavLinkForOwners]: "navigation.forOwnersAndManagers",
  [GtmLabels.HeaderNavLinkForOwnersWelcome]: "navigation.forOwnersAndManagersWelcome",
  [GtmLabels.HeaderNavLinkForOwnersSignIn]: "navigation.forOwnersAndManagersSignIn",
  [GtmLabels.HeaderNavLinkForOwnersCreateAccount]: "navigation.forOwnersAndManagersCreateAccount",
  [GtmLabels.HeaderNavMenuForAdmins]: "navigation.forAdmins",
  [GtmLabels.HeaderNavLinkAdminHome]: "navigation.adminHome",
  [GtmLabels.HeaderNavLinkAdminRecordSearch]: "navigation.adminRecordSearch",
  [GtmLabels.HeaderNavLinkAdminEditUsers]: "navigation.adminEditUsers",
  [GtmLabels.HeaderNavLinkAdminEditOrganizations]: "navigation.adminEditOrg",
  [GtmLabels.HeaderNavLinkAdminRecordImports]: "navigation.adminImports",
  [GtmLabels.HeaderNavLinkAdminUtilities]: "navigation.utilities",
  [GtmLabels.HeaderNavMenuAccount]: "navigation.account",
  [GtmLabels.HeaderNavLinkMyAccount]: "navigation.myAccount",
  [GtmLabels.HeaderNavLinkLogOut]: "navigation.logOut",
  [GtmLabels.HeaderNavLinkHome]: "navigation.hmniLogo",
  [GtmLabels.FooterNavLinkTermsOfService]: "navigation.termsOfService",
  [GtmLabels.FooterNavLinkPrivacyPolicy]: "navigation.privacyPolicy",
  [GtmLabels.FooterNavLinkAccessibilityPolicy]: "navigation.accessibilityPolicy",
  [GtmLabels.FooterNavLinkBackToTop]: "footer.backToTop",
  [GtmLabels.LandingCardAvailableSoon]: "landingPage.availableSoonLink",
  [GtmLabels.LandingCardAvailableSoonBuilding]: "gtm.none",
  [GtmLabels.LandingCardFirstComeFirstServed]: "landingPage.fcfsLink",
  [GtmLabels.LandingCardShortWaitlists]: "landingPage.shortWaitlistLink",
  [GtmLabels.LandingCardMostAffordable]: "landingPage.actionableRbiLink",
  [GtmLabels.LandingCardResources]: "landingPage.learnMore",
  [GtmLabels.FilterSetAvailability]: "filters.availability.title",
  [GtmLabels.FilterSetBedrooms]: "filters.bedrooms.title",
  [GtmLabels.FilterSetEligibility]: "filters.eligibility.title",
  [GtmLabels.FilterSetAffordability]: "filters.affordability.title",
  [GtmLabels.FilterSetMore]: "filters.more.title",
  [GtmLabels.None]: "gtm.none",
};

export const GTM_GLOSSARY_INFO_ICON_LABELS: GtmLabelGroup = {
  ...GTM_DEFAULT_LABELS,
  [GtmLabels.ExplainFilters]: "resultsList.explainFiltersText",
  [GtmLabels.AgeRestrictions]: "listing.isAgeRestricted",
  [GtmLabels.Verified]: "listing.verifiedFlag",
  [GtmLabels.RentBasedOnIncome]: "building.hasDeeplyAffordable",
  [GtmLabels.WaitlistOpen]: "listing.openWaitlist",
  [GtmLabels.WaitlistClosed]: "listing.closedWaitlist",
  [GtmLabels.WaitlistUnknown]: "listing.unknownWaitlist",
};

export const GTM_EXTERNAL_LINK_LABELS: GtmLabelGroup = {
  ...GTM_DEFAULT_LABELS,
  [GtmLabels.WaitlistWebsiteLink]: "listing.sections.waitlists.nextStepsPropertyWebsite",
  [GtmLabels.WaitlistApplicationLink]: "listing.sections.waitlists.nextStepsApplication",
  [GtmLabels.SectionWebsiteLink]: "listing.websiteLabel",
  [GtmLabels.SectionApplicationLink]: "listing.applicationUrlLabel",
  [GtmLabels.MobileWebsiteLink]: "listing.websiteLabel",
  [GtmLabels.EmailAddressLink]: "listing.sections.contactInfo.emailLabel",
  [GtmLabels.PhoneNumberLink]: "listing.sections.contactInfo.phoneLabel",
};

export const GTM_FILTER_LABELS: GtmLabelGroup = {
  ...GTM_DEFAULT_LABELS,
  [GtmLabels.RentBasedOnIncome]: "filters.options.rentBasedOnIncome",
  [GtmLabels.BelowMarketRent]: "filters.options.belowMarketRent",
  [GtmLabels.WaitlistOpen]: "filters.availability.openWaitlist",
  [GtmLabels.WaitlistClosed]: "filters.availability.closedWaitlist",
};

export const GTM_LABELS: Record<GtmTriggers, GtmLabelGroup> = {
  [GtmTriggers.GlossaryInfoIcon]: GTM_GLOSSARY_INFO_ICON_LABELS,
  [GtmTriggers.ExternalLinkClick]: GTM_EXTERNAL_LINK_LABELS,
  [GtmTriggers.ExternalLinkCancel]: GTM_EXTERNAL_LINK_LABELS,
  [GtmTriggers.ExternalLinkOffsite]: GTM_EXTERNAL_LINK_LABELS,
  [GtmTriggers.FilterReset]: GTM_FILTER_LABELS,
  [GtmTriggers.FilterChange]: GTM_FILTER_LABELS,
  [GtmTriggers.NoResultsFound]: GTM_FILTER_LABELS,
  [GtmTriggers.ZeroResults]: GTM_FILTER_LABELS,
  [GtmTriggers.ShareClick]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ShareMenuClick]: GTM_DEFAULT_LABELS,
  [GtmTriggers.SearchLocation]: GTM_DEFAULT_LABELS,
  [GtmTriggers.BuildingCardClick]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ListingExpandCollapse]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ListingPhotos]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ListingMap]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ListingLink]: GTM_DEFAULT_LABELS,
  [GtmTriggers.ListingSectionVisible]: GTM_DEFAULT_LABELS,
  [GtmTriggers.PhotoGalleryOpen]: GTM_DEFAULT_LABELS,
  [GtmTriggers.PhotoGalleryClosed]: GTM_DEFAULT_LABELS,
  [GtmTriggers.PhotoGalleryNavigate]: GTM_DEFAULT_LABELS,
  [GtmTriggers.HeaderNavLink]: GTM_DEFAULT_LABELS,
  [GtmTriggers.HeaderNavMenu]: GTM_DEFAULT_LABELS,
  [GtmTriggers.FooterNavLink]: GTM_DEFAULT_LABELS,
  [GtmTriggers.LandingCardClick]: GTM_DEFAULT_LABELS,
  [GtmTriggers.LandingCardBrowseClick]: GTM_DEFAULT_LABELS,
  [GtmTriggers.BrowserPrint]: GTM_DEFAULT_LABELS,
  [GtmTriggers.PrintSearchResults]: GTM_DEFAULT_LABELS,
  [GtmTriggers.GoogleTranslate]: GTM_DEFAULT_LABELS,
  [GtmTriggers.SaveListing]: GTM_DEFAULT_LABELS,
  [GtmTriggers.PublishListing]: GTM_DEFAULT_LABELS,
  [GtmTriggers.Login]: GTM_DEFAULT_LABELS,
  [GtmTriggers.None]: GTM_DEFAULT_LABELS,
};
