export const localizationMessages = {
  en: {
    // Google Tag Manager
    "gtm.none": " ",
    "gtm.mobileResetSearchFilters": "Mobile: Reset Search Filters",
    "gtm.desktopResetSearchFilters": "Desktop: Reset Search Filters",
    "gtm.noResultsResetSearchFilters": "No Results: Reset Search Filters",
    "gtm.noResultsExpandSearchArea": "No Results: Expand Search Area",
    "gtm.shareEmail": "Email",
    "gtm.shareCopy": "Copy",
    "gtm.shareCopy.copied": "Copied",
    "gtm.shareText": "Text",
    "gtm.sharePrintResults": "Print",
    "gtm.sharePrintListing": "Print Listing",
    "gtm.shareMenuDesktopSearch": "Desktop: Search",
    "gtm.shareMenuDesktopListing": "Desktop: Listing",
    "gtm.shareMenuMobileSearch": "Mobile: Search",
    "gtm.shareMenuMobileListing": "Mobile: Listing",
    "gtm.searchLocation": "Search Location",
    "gtm.buildingCardClick": "Building Card Click Location",
    "gtm.noResults": "No results returned",
    "gtm.zeroResults": "Zero results returned due to filters",
    "gtm.trueZeroResults": "Zero results returned regardless of filters",
    "gtm.nearbyBuildingCardClick": "Building Card Click Nearby Location",
    "gtm.listingSectionTitle": "Section Title",
    "gtm.listingPhotos": "Listing Photos",
    "gtm.listingLink": "Listing Link",
    "gtm.listingSectionVisible": "Listing Section Visible",
    "gtm.photoGalleryOpen": "Photo gallery open",
    // PAGE TITLE
    "title.prefix": "Housing Navigator MA - Affordable Home Rentals in Massachusetts",
    "title.separator": " | ",
    "title.listing": "{name}",
    "title.printResults": "Housing Navigator MA Search Results",
    "title.navigator": "Find affordable home rentals in Massachusetts with Housing Navigator MA",
    "title.landing": "Find affordable home rentals in Massachusetts with Housing Navigator MA",
    "title.propertyManagerDashboard": "{name} Properties Dashboard",
    "title.propertyManagerBuilding": "Edit Listing - {name}",
    "title.propertyManagerPreview": "Preview Listing - {name}",
    "title.propertyManagerWelcome": "Welcome!",

    "building.hasDeeplyAffordable": "Rent Based on Income",
    "building.hasDeeplyAffordableDescription":
      "Rent is based on a percentage of your anticipated yearly income for some units",

    // LISTING GENERAL
    "listing.noSectionContentDefault": "No content provided",
    "listing.listingNotFound": "Listing Not Found",
    "listing.returnToHome": "Return to home page",

    // Navigation
    "navigation.mobile": "show navigation menu",
    "navigation.hmniLogo": "HNMI Logo",
    "navigation.forOwnersAndManagers": "For Owners and Managers",
    "navigation.forOwnersAndManagersWelcome": "Welcome",
    "navigation.forOwnersAndManagersSignIn": "Sign in",
    "navigation.forOwnersAndManagersCreateAccount": "Create an account",
    "navigation.about": "About",
    "navigation.resources": "Resources",
    "navigation.navigator": "Find Housing",
    "navigation.forAdmins": "For Admins",
    "navigation.adminHome": "Admin Home",
    "navigation.adminRecordSearch": "Record Search",
    "navigation.adminEditUsers": "Edit Users",
    "navigation.adminEditOrg": "Edit Organizations",
    "navigation.adminImports": "Record Imports",
    "navigation.utilities": "Utilities",
    "navigation.account": "Account",
    "navigation.myAccount": "My Account",
    "navigation.logOut": "Log Out",
    "navigation.termsOfService": "Terms of Service",
    "navigation.privacyPolicy": "Privacy Policy",
    "navigation.accessibilityPolicy": "Accessibility Statement",
    "navigation.contactUs": "Contact Us",
    "navigation.careers": "Careers",

    // SECTIONS
    "listing.sections.sectionCollapseLabel": "Collapse {title}",
    "listing.sections.sectionExpandLabel": "Expand {title}",
    "listing.sections.titleLoading": "Section Title Loading",
    "listing.sections.propertyDescription.title": "Property Description",
    "listing.sections.propertyDescription.noContent": "No property description has been provided.",
    "listing.sections.propertyDescription.additionalDetailsTitle": "Additional Details",
    "listing.sections.unitInformation.title": "Unit Information",
    "listing.sections.unitInformation.noContent":
      "No unit information has been provided for this property.",
    "listing.sections.amenities.title": "Amenities",
    "listing.sections.amenities.description":
      "The following amenities are included with units in this property:",
    "listing.sections.amenities.noContent":
      "No amenity information has been provided for this property.",
    "listing.sections.utilities.title": "Utilities",
    "listing.sections.utilities.description":
      "The following utilities are included with units in this property:",
    "listing.sections.utilities.noContent":
      "No utility information has been provided for this property.",
    "listing.sections.accessibility.title": "Accessibility",
    "listing.sections.accessibility.description":
      "There will be {unitCount, plural, =0 {no accessible units} one {one accessible unit} other {# accessible units}}.",
    "listing.sections.accessibility.unitsHeader": "Accessible Units",
    "listing.sections.accessibility.universalDesignHeader": "Universal Design",
    "listing.sections.accessibility.accessibleAmenitiesExplainer":
      "The following features may be available at this property in some units:",
    "listing.sections.accessibility.noContent":
      "No accessibility information has been provided for this property.",
    "listing.sections.accessibility.mobility": "Mobility",
    "listing.sections.accessibility.vision": "Vision",
    "listing.sections.accessibility.hearing": "Hearing",
    "listing.sections.petPolicy.title": "Pet Policy",
    "listing.sections.petPolicy.noContent":
      "No pet policy information has been provided for this property.",
    "listing.sections.petPolicy.allowedDescription": "Pets are allowed at this property.",
    "listing.sections.petPolicy.notAllowedDescription":
      "No pets allowed at this property. If you have a service animal, contact the property for information about accommodations.",
    "listing.sections.contactInfo.title": "Contact Information",
    "listing.sections.contactInfo.noContent":
      "No contact information has been provided for this property.",
    "listing.sections.contactInfo.managementCompanyLabel": "Management Company",
    "listing.sections.contactInfo.phoneLabel": "Phone number",
    "listing.sections.contactInfo.emailLabel": "Email Address",
    "listing.sections.map.title": "Map",
    "listing.sections.map.staticMapAltText": "Map of property location",
    "listing.sections.map.staticMapOverlayText": "Click to interact",
    "listing.sections.map.noContent":
      "No location information has been provided for this property.",
    "listing.sections.documents.title": "Attachments & Documents",
    "listing.sections.documents.category":
      "{category, select, brochure { Brochure } application { Application } other { Other Document }}",
    "listing.sections.documents.lotteryCategory":
      "{category, select, brochure { Lottery Brochure } application { Lottery Application } other { Other Lottery Document }}",
    "listing.sections.documents.fcfsCategory":
      "{category, select, applicationDocuments { Documents needed to apply } moveInRequirements { Move in requirements } eligibilityRequirements { Application/eligibility preferences or requirements } other { Other Document }}",
    "listing.sections.documents.noContent":
      "This property does not have any attachments or documents.",
    "listing.sections.photos.title": "Photos",
    "listing.sections.photos.noContent": "This property does not have any photos.",
    "listing.sections.waitlists.title": "Waitlist Details",
    "listing.sections.waitlists.noContent": "This property does not have any waitlist information.",
    "listing.sections.waitlists.shortWaitlistUnitsHeader": "Short Waitlist Units",
    "listing.sections.waitlists.waitlistOpenDescription":
      "You can apply to this property’s waitlist for the bedroom sizes shown. Click on the property’s website or contact the property to learn more. Housing Navigator does not itself take applications.",
    "listing.sections.waitlists.waitlistClosedDescription":
      "This property is not accepting new applications for the bedroom sizes below. You can use the search tools to find other similar opportunities. You can also check back to see if the list re-opens.",
    "listing.sections.waitlists.shortWaitlistDescription":
      "You can apply to this property’s short waitlist for the bedroom sizes shown. If you apply to a short waitlist, you can expect to hear back within 6 months. This is only an estimate and your actual wait may be longer. Click on the property’s website or contact the property to learn more. Housing Navigator does not itself take applications.",
    "listing.sections.waitlists.nextSteps": "Next Steps",
    "listing.sections.waitlists.nextStepsApplication": "Application Information",
    "listing.sections.waitlists.nextStepsPropertyWebsite": "Property Website",
    "listing.sections.waitlists.nextStepsContact": "Property Contact Information",
    "listing.sections.waitlists.prioritiesAndPreferences": "Priorities and Preferences",
    "listing.sections.contactForMore": "Contact the property for more information.",
    "listing.sections.lottery.title": "Lottery Details",
    "listing.sections.lottery.noContent": "This property does not have any lottery information.",
    "listing.sections.lottery.lotteryApplicationDeadline.header": "Lottery Application Deadline",
    "listing.sections.lottery.lotteryApplicationDeadline.endDate": "{endDate, date, full}",
    "listing.sections.lottery.lotteryApplicationDeadline.description1":
      "You can apply to this property’s lottery until ",
    "listing.sections.lottery.lotteryApplicationDeadline.description2": ". Click on the ",
    "listing.sections.lottery.lotteryApplicationDeadline.websiteLinkText": "property’s website",
    "listing.sections.lottery.lotteryApplicationDeadline.description3": " or ",
    "listing.sections.lottery.lotteryApplicationDeadline.contactLinkText": "contact the property",
    "listing.sections.lottery.lotteryApplicationDeadline.description4":
      " to learn more. Housing Navigator does not itself take applications.",
    "listing.sections.lottery.lotteryDetails.header": "Lottery Details",
    "listing.sections.lottery.availableUnits.header": "Available Units",
    "listing.sections.lottery.availableUnits.header.v2": "Available Units in Lottery",
    "listing.sections.lottery.accessibility.header": "Accessibility and Universal Design",
    "listing.sections.lottery.infoSessions.header": "Information Sessions",
    "listing.sections.lottery.infoSessions.linkText": "Info session link",
    "listing.sections.lottery.infoSessions.date": "{date, date, full}",
    "listing.sections.lottery.infoSessions.time": "at {date, time, full}",
    "listing.sections.lottery.otherDetails.header": "Additional Lottery Details",
    "listing.sections.lottery.otherDetails.requirements.firstMonthsRent":
      "First month’s rent required",
    "listing.sections.lottery.otherDetails.requirements.lastMonthsRent":
      "Last month’s rent required",
    "listing.sections.lottery.otherDetails.requirements.securityDeposit":
      "Security deposit required",
    "listing.sections.lottery.otherDetails.requirements.creditCheck": "Credit check required",
    "listing.sections.lottery.otherDetails.requirements.lockAndKey":
      "Cost of lock and key required",
    "listing.sections.lottery.otherDetails.requirements.landlordReferences":
      "Landlord references required",
    "listing.sections.lottery.otherDetails.requirements.cori":
      "Criminal background check (CORI) required",
    "listing.sections.lottery.otherDetails.requirements.incomeVerification":
      "Income verification documentation required",
    "listing.sections.lottery.otherDetails.accommodations.header":
      "Accommodations for Applicants with Limited English Proficiency",
    "listing.sections.lottery.otherDetails.requirements.header": "Move-in Requirements",
    "listing.sections.lottery.contact.header": "Lottery Contact Information",
    "listing.sections.lottery.contact.nameLabel": "Contact Name",
    "listing.sections.lottery.contact.emailLabel": "Email Address",
    "listing.sections.lottery.contact.phoneLabel": "Phone Number",
    "listing.sections.lottery.contact.website": "Website",
    "listing.sections.lottery.contact.buttonText": "Learn More",

    "listing.sections.fcfs.title": "First Come, First Served Details",
    "listing.sections.fcfs.noContent":
      "This property does not have any First Come, First Served information.",
    "listing.sections.fcfs.details.header": "Description",
    "listing.sections.fcfs.availableUnits.header": "Available Units",
    "listing.sections.fcfs.otherDetails.header": "Additional Details",
    "listing.sections.fcfs.contact.header": "First Come, First Served Contact Information",
    "listing.sections.fcfs.contact.emailLabel": "Email Address",
    "listing.sections.fcfs.contact.phoneLabel": "Phone Number",

    "listing.verifiedFlag": "Verified Listing",
    "listing.lotteryFlag": "Lottery Open",
    "listing.accessibleUnits": "Accessible Units",
    "listing.noBannerAltText": "Banner image not provided",
    "listing.defaultBannerAltText": "Banner image for listing",
    "listing.defaultImageAltText": "Image for property",
    "listing.imageAltText": "{location} image for {name}",
    "listing.showPhotosButtonText": "Photos",
    "listing.unitDetails": "Unit Details",
    "listing.noUnitsAvailable": "No units available",
    "listing.applicationRequirements": "Application Requirements",
    "listing.moveInRequirements": "Move-in Requirements",
    "listing.attachments": "Attachments",
    "listing.visitWebsite": "Visit Website",
    "listing.websiteLabel": "Property Website",
    "listing.applicationUrlLabel": "Application Link",
    "listing.lotteryUrlLabel": "Lottery Website",
    "listing.backToResults": "Back To Results",
    "listing.backToHome": "Back To Home",
    "listing.back": "Back",
    "listing.map.streetView": "Street View",
    "listing.map.directions": "Directions",
    "listing.propertyResources": "Property Resources",
    "listing.propertyResourceLinks": "Property Links",
    "listing.propertyResourceDocs": "Property Documents",
    "listing.propertyLotteryDocs": "Lottery Documents",
    "listing.propertyLotteryResources": "Lottery Resources",
    "listing.propertyFcfsResources": "First Come, First Served Resources",

    // PREFERENCES
    "listing.preferences.noPreferences.waitlist":
      "No priorities and preferences are available for the waitlist.",
    "listing.preferences.noPreferences.lottery":
      "No priorities and preferences are available for the lottery.",
    "listing.preferences.noPreferences": "No priorities and preferences are available.",

    // CHAMP Content
    "listing.preferences.champ.details":
      "This site is state-funded public housing, meaning that some or all units must give preference status, in the following order, to people experiencing:",
    "listing.preferences.champ.item1":
      "Homelessness due to displacement by Natural Forces (ie, fire, earthquake, flood)",
    "listing.preferences.champ.item2":
      "Homelessness due to displacement by Public Action (Urban Renewal)",
    "listing.preferences.champ.item3":
      "Homelessness due to displacement by Public Action (Sanitary Code Violation)",
    "listing.preferences.champ.item4":
      "Homelessness due to displacement or imminent danger due to the following reasons",
    "listing.preferences.champ.item4.linkText": "DHCD Housing Situation Priority Policy",
    "listing.preferences.champ.item4a":
      "Causes Other than the Fault of the Applicant or their Household (No Fault Eviction)",
    "listing.preferences.champ.item4b":
      "Severe Medical Emergency (Housing Situation negatively contributes to Medical Situation)",
    "listing.preferences.champ.item4c":
      "Abusive Situation (Currently Living with Abusive Situation, or displaced due to Abusive Situation)",
    "listing.preferences.champ.item5": "Alternative Housing Voucher Program (AHVP) participant",
    "listing.preferences.champ.item6": "Transfer for Good Cause",
    "listing.preferences.champ.item7": "All other applicants",
    "listing.preferences.champ.postContent1":
      "State Public Housing also has two preference categories; Veteran and Local Residents.",
    "listing.preferences.champ.postContent2":
      "Priority, if verified, affects your ranking on Housing Authority waitlists. Preferences are applied within priority levels. For applicants with the same verified priority and preference, those with earlier application dates will be ranked higher in the waitlist. Please note that you must provide documentation to verify priority and preference claims. You may still obtain housing even if you do not have a priority or preference claim.",
    "listing.preferences.champ.postContent3":
      "For more information on applying to state-aided public housing, see",
    "listing.preferences.champ.postContent3.linkText": "How to Apply for Public Housing",

    // Masshousing Content
    "listing.preferences.masshousing.details":
      "This site has received funding from MassHousing, meaning that some or all units must give preference status, in the following order, to people experiencing:",
    "listing.preferences.masshousing.item1":
      "Homelessness due to displacement by Natural Forces (ie, fire, earthquake, flood)",
    "listing.preferences.masshousing.item2":
      "Homelessness due to displacement by Public Action (Urban Renewal)",
    "listing.preferences.masshousing.item3":
      "Homelessness due to displacement by Public Action (Sanitary Code Violation)",
    "listing.preferences.masshousing.item4": "Involuntary Displacement by Domestic Violence",
    "listing.preferences.disclaimer":
      "A preference ranks your order higher in a lottery or on a waitlist. Preferences are not requirements and may not apply to all units. You may still obtain housing even if you do not have a preference.",
    "listing.preferences.contact": "For more information, contact the management office.",

    // STATUS
    // New Keys
    "listing.availableNow": "Available Now",
    "listing.availableSoon": "Available Soon",
    "listing.openWaitlist": "Waitlist Open",
    "listing.closedWaitlist": "Waitlist Closed",
    "listing.unknownWaitlist": "Waitlist Unknown",
    "listing.shortWaitlist": "Short Waitlist",
    "listing.lottery": "Lottery Application Open",
    "listing.fcfs": "First Come, First Served Open",
    "listing.openWaitlistExplainer": "The waitlist is open for:",
    "listing.closedWaitlistExplainer": "The waitlist is closed for:",
    "listing.unknownWaitlistExplainer": "The waitlist status is unknown for:",
    "listing.shortWaitlistExplainer": "Applicants are estimated to hear back within 6 months for:",
    "listing.shortWaitlistExplainer.v2":
      "Waitlist applicants are estimated to hear back within 6 months for the following units:",
    "listing.lotteryExplainer": "The application deadline is",
    "listing.lotteryExplainer.v2": "The lottery application deadline is",
    "listing.lotteryExplainerDate": `{endDate, date, full}`,
    "listing.waitlistDetailsLinkText": "Additional Waitlist Details",
    "listing.waitlistUnknownLinkText": "Property Contact Information",
    "listing.lotteryDetailsLinkText": "Additional Lottery Details",
    "listing.fcfsDetailsLinkText": "First Come, First Served Details",
    // Old keys
    "listing.waitlistOpen": "open",
    "listing.waitlistClosed": "closed",
    "listing.expiresOn": "Expires: {date}",
    "listing.waitlist": "Waitlist",
    "listing.firstComeFirstServe": "First Come, First Served",

    // AGE RESTRICTIONS
    // New age restriction keys
    "listing.isAgeRestricted": "Age Restricted",
    "listing.ageRestricted60plus": "60+",
    "listing.ageRestricted55plus": "55+",
    "listing.ageRestricted58plus": "58+",
    "listing.ageRestricted62plus": "62+",
    "listing.ageRestrictedOrDisabledConjunction": "and/or",
    "listing.ageRestrictedOrDisabledText": "Disability",
    "listing.ageRestrictedSpecificDescription":
      "{size, select, all {Every resident} other {At least one resident}} in the household must meet age restriction {orDisabled, select, yes {or have a disability } other {}}to qualify",
    // Old age restriction keys
    "listing.ageRestriction": "Age Restrictions",
    "listing.ageRestrictionAll": "All household members must be {ageRestriction}",
    "listing.ageRestrictionOne": "At least one household member must be {ageRestriction}",
    "listing.ageRestrictionDisabled": "or disabled",

    // BEDROOM TYPES
    "bedrooms.sro": "SRO",
    "bedrooms.studio": "Studio",
    "bedrooms.1br": "1BR",
    "bedrooms.2br": "2BR",
    "bedrooms.3br": "3BR",
    "bedrooms.4+br": "4+BR",
    "bedrooms.4br": "4BR",
    "bedrooms.5br": "5BR",
    "bedrooms.6br": "6BR",
    "bedrooms.7br": "7BR",
    "bedrooms.8br": "8BR",
    // Upcased versions for convenience
    "bedrooms.upcase.sro": "SRO",
    "bedrooms.upcase.studio": "STUDIO",
    "bedrooms.upcase.1br": "1BR",
    "bedrooms.upcase.2br": "2BR",
    "bedrooms.upcase.3br": "3BR",
    "bedrooms.upcase.4+br": "4+BR",
    "bedrooms.upcase.4br": "4BR",
    "bedrooms.upcase.5br": "5BR",
    "bedrooms.upcase.6br": "6BR",
    "bedrooms.upcase.7br": "7BR",
    "bedrooms.upcase.8br": "8BR",

    // Versions with no "BR" for filter text
    "bedrooms.filter.sro": "SRO",
    "bedrooms.filter.studio": "Studio",
    "bedrooms.filter.1br": "1",
    "bedrooms.filter.2br": "2",
    "bedrooms.filter.3br": "3",
    "bedrooms.filter.4+br": "4+",
    "bedrooms.filter.4br": "4",
    "bedrooms.filter.5br": "5",
    "bedrooms.filter.6br": "6",
    "bedrooms.filter.7br": "7",
    "bedrooms.filter.8br": "8",

    // Unit Accessibility Types
    "unitAccessibility.mobility": "Mobility",
    "unitAccessibility.vision": "Vision",
    "unitAccessibility.hearing": "Hearing",

    "listing.downloadAttachments": "Download Attachments",
    "listing.rentBasedOnIncome": "Rent Based on Income",
    "listing.rentBasedOnIncomeUnits": "Rent Based on Income Units",
    "listing.statusSummary": "Property Status Summary",
    "listing.rentBasedOnIncomeMessage":
      "This property includes units where rent is set to a percentage of the household income, for example 30% of monthly income.",
    "listing.minIncomePerYear": "Minimum Income per year",
    "listing.unitPropertyInfo": "Unit/Property Info",
    "listing.amenities": "Amenities",
    "listing.utilities": "Utilities",
    "listing.accessibility": "Accessibility and Universal Design",
    "listing.petPolicy": "Pet Policy",
    "listing.location": "Location",
    "listing.contactInfo": "Contact Info",
    "listing.numberOfBedrooms": "Number of Bedrooms",
    "listing.mapMissing": "Map Missing",
    "listing.unitInfoTables.unitCount": "{num, plural, =0 {0 units} one {# unit} other {# units}}",
    "listing.unitInfoTables.accessibleUnitCount":
      "{floating, select, yes {Up to } other{}}{num, plural, =0 {0 accessible units} one {# accessible unit} other {# accessible units}}",
    "listing.unitInfoTables.bathroomCount": "{num}BA",
    "listing.unitInfoTables.upToMaxIncome": "Up to ${num, number, group-min2 currency/USD}",
    "listing.unitInfoTables.incomeRange": "{min} - {max}",
    "listing.unitInfoTables.rentRange":
      "${min, number, group-min2 currency/USD} - {max, number, group-min2 currency/USD}",
    "listing.unitInfoTables.rentSingle": "${num, number, group-min2 currency/USD}",
    "listing.unitInfoTables.rentUnknown": "Not Provided",
    "listing.unitInfoTables.rbiRent": "~30% of household income",
    "listing.unitInfoTables.maxIncome": "${num, number, group-min2 currency/USD}",
    "listing.unitInfoTables.minIncome": "${num, number, group-min2 currency/USD}",
    "listing.unitInfoTables.minIncomeUnknown": "{symbol}Inquire",
    "listing.unitInfoTables.maxIncomeUnknown": "{symbol}Inquire",
    "listing.unitInfoTables.householdSize":
      "{num, plural, =0 {0 Household Members} one {# Household Member} other {# Household Members}}",
    "listing.unitInfoTables.accessibleHouseholdSize":
      "{num, plural, =0 {For 0 Household Members} one {For # Household Member} other {For # Household Members}}",
    "listing.unitInfoTables.minIncomeFootnote":
      "{symbol} No minimum income information has been provided for these units. Contact the property for more information",
    "listing.unitInfoTables.partialRentFootnote":
      "{symbol} Rent information has not been provided for all of these units.  Contact the property for more information.",
    "listing.unitInfoTables.ami": "{num}%",
    "listing.unitInfoTables.rentHeader": "Monthly Rent",
    "listing.unitInfoTables.bedroomsHeader": "Bedrooms",
    "listing.unitInfoTables.unitSizeHeader": "Unit Size",
    "listing.unitInfoTables.bathroomsHeader": "Baths",
    "listing.unitInfoTables.incomeHeader": "Approx Yearly Income",
    "listing.unitInfoTables.amiHeader": "AMI",
    "listing.unitInfoTables.amiTitle": "Show Area Median Income (AMI)",
    "listing.unitInfoTables.accessibilityHeader": "Accessibility",
    "listing.unitInfoTables.householdSize.v2":
      "{size, plural, =0 {0 people} one {# person} other {# people}}",
    "listing.unitInfoTables.rbiHeader": "Rent Based on Income",
    "listing.unitInfoTables.rbiDescription": `Your rent will be set based on a percentage of your household’s income (usually 30%).

Income requirements are estimated using Area Median Income (AMI) and may depend on household size; contact the property for more information.
`,
    "listing.unitInfoTables.rbiHeader.v2": "Rent Based on Income Units",
    "listing.unitInfoTables.rbiDescription.v2":
      "Rent is set based on a percentage of household income (usually 30%).",
    "listing.unitInfoTables.flatRateHeader": "Fixed Below Market Rent",
    "listing.unitInfoTables.flatRateDescription": `Your rent will be a fixed amount set by the property. Rents are subject to change; contact the property for more information.

Income requirements are estimated using Area Median Income (AMI) and may depend on household size; contact the property for more information.

If you have a rental voucher, the income requirements do not apply and you will pay rent based on the program (such as mobile Section 8, HCVP, MRVP, or AHVP).`,
    "listing.unitInfoTables.flatRateHeader.v2": "Fixed Below Market Rent Units",
    "listing.unitInfoTables.flatRateDescription.v2":
      "Rent is set below the market rate at an amount affordable for the area, but does not adjust to the particular household’s income.",

    // Footer
    "footer.subscribeComplete": "Thank you for subscribing.",
    "footer.subscribeToOurNewsletter": "Sign up for news and properties",
    "footer.emailLabel": "Email Address",
    "footer.firstName": "First Name",
    "footer.lastName": "Last Name",
    "footer.signupButtonText": "Sign Up",
    "footer.newsCheckbox": "News",
    "footer.listingsCheckbox": "Property Listings",
    "footer.hnmiLogoAltText": "Housing Navigator Massachusetts Logo",
    "footer.eohLogoAltText": "Equal Opportunity Housing Logo",
    "footer.copyright": "Ⓒ {year} Housing Navigator Massachusetts, Inc",
    "footer.linkedIn": "Housing Navigator LinkedIn",
    "footer.backToTop": "Back to Top",

    "dialog.close": "Close",
    "dialog.photoText": "Photo {index} of {total}",
    "dialog.accessiblePhotoText": "Acessibility photo {index} of {total}",
    "dialog.accessibilityFeaturesLabel": "Shows accessibility features",
    "unitDetailsTable.size": "Size",
    "unitDetailsTable.numberAvailable": "Number Available",
    "unitDetailsTable.bathrooms": "Bathrooms",
    "unitDetailsTable.accessibility": "Accessibility",
    "unitDetailsTable.rentInfo": "Rent Info",
    "unitDetailsTable.moreInfo": "More info",
    "unitDetailsTable.lessDetails": "Less details",
    "unitDetailsTable.moreDetails": "More details",

    // Listing Card
    "listingCard.selectListingLabel": "Select Listing: {name}",
    "listingCard.viewListing": "View Listing",
    "listingCard.viewListingLabel": "View Listing: {name}",
    "listingCard.ageRestrictionType.age55plus": "Age Restricted 55+",
    "listingCard.ageRestrictionType.age60plus": "Age Restricted 60+",
    "listingCard.ageRestrictionType.age62plus": "Age Restricted 62+",
    "listingCard.ageRestrictionType.other": "Age Restricted",
    "listingCard.ageRestrictionType.none": "Open to All Ages",
    "listingCard.availability.waitlistOpen": "Waitlist Open",
    "listingCard.availability.lottery": "Lottery Open",
    "listingCard.availability.shortWaitlist": "Short Waitlist",
    "listingCard.availability.fcfs": "First Come, First Served Open",
    "listingCard.availability.waitlistClosed": "Waitlist Closed",
    "listingCard.availability.unknown": "Waitlist Unknown",
    "listingCard.isVerified": "Verified",
    "listingCard.accessibilityTooltip": "Including accessible units",
    // Rent text on card
    "listingCard.rent.onlyRbi": "Rents based on income",
    "listingCard.rent.rbiAndMax":
      "Rents based on income, or up to {maxRent, number, ::precision-integer currency/USD}",
    "listingCard.rent.rbiAndFixed": "Rents based on income, or fixed below market rent",
    "listingCard.rent.unknownRent": "Rents unknown",
    "listingCard.rent.exactRent": "Rent is {rent, number, ::precision-integer currency/USD}",
    "listingCard.rent.rentRange":
      "Rents from {minRent, number, ::precision-integer currency/USD} - {maxRent, number, ::precision-integer currency/USD}",

    "resultsList.srTitle": "Search Results",
    "resultsList.explainFiltersText": "Explain Filters",
    "resultsList.filtersDialogToggleText": "Filters",
    "resultsList.resultsText": "Results",
    "resultsList.resultsTextWithCount":
      "{count} {count, plural, =0 { Results } one { Result } other { Results }}",
    "resultsList.pageSummary": "Page {page} of {pageCount}",
    "resultsList.resetFilters": "Reset Search Filters",
    "resultsList.resetFiltersShort": "Reset Filters",
    "resultsList.expandSearchArea": "Expand Search Area",
    "resultsList.findAllNearby": "Find nearest matches",
    "resultsList.noMatchingResults": "No results match your search criteria",
    "resultsList.zeroResultsNextSteps": "Try searching another location",
    "resultsList.endMatchingResults": "End of results matching your search criteria",
    "resultsList.nearbyResultCount":
      "{count} Nearby {count, plural, =0 { Listings } one { Listing } other { Listings }}",
    "resultsList.additionalNearbyResultCount":
      "Showing {count} more nearby {count, plural, =0 { results } one { result } other { results }}",
    "resultsList.onlyNearbyResultCount":
      "Showing {count} nearby {count, plural, =0 { results } one { result } other { results }}",
    "resultsList.mapView": "Map View",
    "resultsList.listView": "List View",
    "resultsList.filterDrawer.title": "Filter Search",
    "resultsList.filterDrawer.closeModal": "Close Filters Dialog",
    "resultsList.filterDrawer.locationTitle": "Search Location",
    "search.defaultSearchPlaceholder": "Where would you like to live?",
    "search.searchLabel": "Search by city, street, or zip code...",
    "resultsList.noCriteriaText": "Where would you like to live?",
    "resultsList.noCriteriaDescription": "[Enter search location to get started]",
    "resultsList.noResultsText": "No Results Found",
    "resultsList.noResultsDescription":
      "We’re sorry, but there are no results matching your search criteria in this area.",
    "map.searchThisArea": "Search This Area",
    "map.srTitle": "Map",

    // Sharing
    "share.shareSearchDesktop": "Share",
    "share.shareSearchMobile": "Share",
    "share.shareSearchMenuTitle": "Share This Search",
    "share.shareListingDesktop": "Share",
    "share.shareListingMobile": "Share",
    "share.shareListingMenuTitle": "Share This Listing",
    "share.emailBodyListing": "Check out {description} on Housing Navigator! {href}",
    "share.emailBodyResults": "Check out these listings on Housing Navigator! {href}",
    "share.textBodyListing": `Check out {description} on Housing Navigator! {href}`,
    "share.textBodyResults": `Check out these listings on Housing Navigator! {href}`,

    // Landing Page
    "landingPage.searchButtonText": "Search",
    "landingPage.title": "Find Housing in Massachusetts",
    "landingPage.subtitle": "Whether you’re on a fixed income or make over $100,000 a year",
    "landingPage.availableSoonAltText": "Lotteries", // Alt text is not current used, as image is decorative
    "landingPage.availableSoonTitle": "Available Soon",
    "landingPage.availableSoonSubtitle": "Open Lotteries",
    "landingPage.availableSoonDialogTitle": "Upcoming Lotteries",
    "landingPage.availableSoonDescription":
      "There {count, plural, =0 { are no properties } one { is 1 property } other {are {count} properties}} accepting applications for upcoming lotteries and openings.",
    "landingPage.availableSoonDescription.v2":
      "There {count, plural, =0 { are no properties } one { is 1 property } other {are {count} properties}} with <b>{count, plural, =0 { open lotteries } one { an open lottery } other {open lotteries}}</b>.",
    "landingPage.table.nameHeader": "Building Name",
    "landingPage.table.cityHeader": "City",
    "landingPage.table.dateHeader": "Lottery Date",
    "landingPage.table.dateCell": "Apply by {endDate, date, short}",
    "landingPage.table.dateCell.v2": "Apply by <b>{endDate, date, short}</b>",
    "landingPage.availableSoonLink": "View all lotteries on map",
    "landingPage.browseLink": "See More Listings",
    "landingPage.mapLink": "Show On Map",
    "landingPage.availableSoonLink.v2": "Show On Map",
    "landingPage.fcfsAltText": "First Come, First Served", // Alt text is not current used, as image is decorative
    "landingPage.fcfsTitle": "First Come, First Served",
    "landingPage.fcfsTitle.v2": "Available Now",
    "landingPage.fcfsSubtitle": "First Come, First Served",
    "landingPage.fcfsLink": "View all properties on map",
    "landingPage.fcfsLink.v2": "Show On Map",
    "landingPage.fcfsDescription":
      "There {count, plural, =0 { are no units } one { is 1 unit } other {are {count} units}} available for immediate occupancy.",
    "landingPage.fcfsDescription.v2":
      "There {count, plural, =0 { are no units } one { is 1 unit } other {are {count} units}} <b>available for immediate occupancy</b>.",
    "landingPage.fcfsDialogTitle": "Units Available Now",
    "landingPage.shortWaitlistTitle": "Short Waitlists",
    "landingPage.shortWaitlistLink": "View all short waitlists on map",
    "landingPage.shortWaitlistDescription":
      "There {count, plural, =0 { are no properties } one { is 1 property } other {are {count} properties}} where an applicant can expect to hear back within 6 months.",
    "landingPage.shortWaitlistTitle.v2": "Available Soon",
    "landingPage.shortWaitlistSubtitle": "Short Waitlists",
    "landingPage.shortWaitlistLink.v2": "Show On Map",
    "landingPage.shortWaitlistDescription.v2":
      "There {count, plural, =0 { are no properties } one { is 1 property } other {are {count} properties}} for which <b>applicants should expect to hear back within six months</b>.",
    "landingPage.shortWaitlistDialogTitle": "Short Waitlists",
    "landingPage.actionableRbiTitle": "Most Affordable",
    "landingPage.actionableRbiSubtitle": "Rent Based on Income",
    "landingPage.actionableRbiLink": "Show On Map",
    "landingPage.actionableRbiDescription":
      "There {count, plural, =0 { are no properties } one { is 1 property } other {are {count} properties}} with <b>rent based on income (RBI) units that are available now or have short waitlists</b>. Rent for these properties will generally be 30% of the renter’s income. Please note that if you need a more affordable option, getting on the waitlist for one or more properties with RBI units may be the fastest path to an affordable home. {learnMoreLink}",
    "landingPage.actionableRbiLearnMoreText": "Learn more",
    "landingPage.actionableRbiDialogTitle": "Most Affordable",
    "landingPage.learnMore": "Learn More",
    "landingPage.resourcesLinkText": "Go to Help Center",
    "landingPage.resourcesAltText": "Resources Icon", // Alt text is not current used, as image is decorative
    "landingPage.resourcesTitle": "Resources",
    "landingPage.resourcesHereToHelp": "We are here to help you find housing.",
    "landingPage.resourcesDescription": "Learn more about using this website to search for a home.",
    "landingPage.goToHelpLinkText": "Go To Help Center",
    "landingPage.whatsOnThisSiteTitle": "What is this?",
    "landingPage.aboutHousingNavigator": "About Housing Navigator",
    "landingPage.whoSiteForTitle": "Who is this site for?",
    "landingPage.whoSiteForP1":
      "Housing Navigator Massachusetts, Inc. is a 501(c)(3) nonprofit striving to create quality, actionable listings on affordable (income-restricted) rental properties throughout Massachusetts. We strive to provide reliable information.",
    "landingPage.whoSiteForP2":
      "We must rely on property owners for a significant portion of listing details and are not responsible for inaccuracies. {learnMoreLink} and {supportUsLink}.",
    "landingPage.whoSiteForP2.learnMoreText": "Learn More",
    "landingPage.whoSiteForP2.supportUsText": "Support Us",
    "landingPage.whoSiteForP3":
      "You can also find information about current affordable housing opportunities at Metrolist. If you are looking for affordable homeownership please visit {myMassHomeLink}.",
    "landingPage.whoSiteForP3.myMassHomeText": "MyMassHome",
    "landingPage.whoSiteForP4":
      "Accessible affordable rentals, previously listed on MassAccess, are now listed here.",
    "landingPage.whatsOnThisSiteDescription":
      "Housing Navigator Massachusetts, Inc. is a 501(c)(3) nonprofit striving to create quality, actionable listings on affordable (income-restricted) rental properties throughout Massachusetts. We strive to provide reliable information. We must rely on property owners for a significant portion of listing details and are not responsible for inaccuracies.",
    "landingPage.learnMoreLinkText": "Learn More",
    "landingPage.whatsOnThisSiteDescriptionLinkConjunction": "and",
    "landingPage.supportUsLinkText": "Support Us",
    "landingPage.whatsOnThisSiteDescription2":
      "You can also find information about current affordable housing opportunities at",
    "landingPage.metrolist": "Metrolist",
    "landingPage.massHousing": "MassHousing",
    "landingPage.whatsOnThisSiteDescription2AgenciesConjunction": "or",
    "landingPage.massAccess": "MassAccess",
    "landingPage.myMassHomesDescription":
      "If you are looking for affordable homeownership please visit",
    "landingPage.myMassHomes": "MyMassHome",

    "landingPage.getStartedTitle": "Get Started",
    "landingPage.getStartedSubtitle": "Begin Your Housing Search",
    "landingPage.beingSearchText": "Start Searching",
    "landingPage.learnMoreText": "Learn More",

    "landingPage.massAccessReferral":
      "Accessible affordable rentals, previously listed on MassAccess, are now listed here.",
    "landingPage.verifiedDescription1":
      "Property owners and managers have graciously verified listings wherever the",
    "landingPage.verifiedDescription2": "icon appears.",
    "landingPage.donorsTitle": "Our Collaborators",
    "landingPage.dhcdLogoAltText":
      "Logo for Executive Office of Housing and Livable Communities (EOHLC)",
    "landingPage.mhpLogoAltText": "Logo for Massachusetts Housing Partnership (MHP)",
    "landingPage.masshousingLogoAltText": "Logo for MassHousing",
    "landingPage.massDevelopmentLogoAltText": "Logo for MassDevelopment",

    // Glossary
    "glossary.title": "Glossary",
    "glossary.closeModal": "Close Glossary",
    "glossary.tooltip": "More Info",
    "glossary.terms.ageRestricted.term": "Age Restricted",
    "glossary.terms.ageRestricted.definition": "Your household must meet age requirements.",
    "glossary.terms.ageRestrictedOrDisabled.term": "Age Restricted and/or Disabled",
    "glossary.terms.ageRestrictedOrDisabled.definition":
      "Your household must meet age requirements and/or have a disability.",
    "glossary.terms.fcfs.term": "First Come, First Served",
    "glossary.terms.fcfs.definition": "Units have immediate vacancies with no one on the waitlist.",
    "glossary.terms.lottery.term": "Lottery",
    "glossary.terms.lottery.definition":
      "Property has active vacancies that can be applied for through a housing lottery.",
    "glossary.terms.rentBasedOnIncome.term": "Rent Based on Income",
    "glossary.terms.rentBasedOnIncome.definition":
      "Your rent will be set based on a percentage of your household’s income (usually 30%).",
    "glossary.terms.fixedBelowMarketRent.term": "Fixed Below Market Rent",
    "glossary.terms.fixedBelowMarketRent.definition":
      "Rent is set below the market rate at an amount affordable for the area but does not adjust to the particular household’s income.",
    "glossary.terms.waitlist.term": "Waitlist",
    "glossary.terms.waitlist.definition":
      "Property is currently occupied and has no active vacancies; waitlists may be open or closed to new applicants.",
    "glossary.terms.sro.term": "SRO",
    "glossary.terms.sro.definition":
      "Single Room Occupancy, a single room unit that shares a bathroom and/or kitchen.",
    "glossary.terms.studio.term": "Studio",
    "glossary.terms.studio.definition": "Single room unit with kitchen and bath.",
    "glossary.terms.oneBr.term": "1 Bedroom",
    "glossary.terms.oneBr.definition": "More than one room with both kitchen & bath.",
    "glossary.terms.verified.term": "Verified",
    "glossary.terms.verified.definition":
      "The property owner or manager has reviewed and verified information about this property listing.",
    "glossary.terms.mobility.term": "Mobility",
    "glossary.terms.mobility.definition":
      "Features that allow people with mobility disabilities, including individuals who use wheelchairs or walkers, to navigate and use a unit. These may include barrier-free design, and roll-under counters.",
    "glossary.terms.vision.term": "Vision",
    "glossary.terms.vision.definition":
      "Features that allow people with limited or no vision to navigate and use a unit. These may include braille labels and contrasted edges on surfaces.",
    "glossary.terms.hearing.term": "Hearing",
    "glossary.terms.hearing.definition":
      "Features that allow D/deaf or hard of hearing people to navigate and use a unit. These may include visual alarms and doorbells and soundproofing to reduce echoes or background noise.",
    "glossary.terms.shortWaitlist.term": "Short Waitlist",
    "glossary.terms.shortWaitlist.definition":
      "Properties with open waitlists where an applicant can expect to hear back within 6 months.",

    // Filters
    "filters.srTitle": "Search Filters and Options",
    "filters.clearFilterSet": "Clear {title} Filter",
    "filters.options.title": "Options",
    "filters.options.ageRestricted": "Age Restricted",
    "filters.options.ageRestriction": "Age Restriction",
    "filters.options.ageRestrictionLabel": "Age Minimum",
    "filters.options.notAgeRestricted": "Not Age Restricted",
    "filters.options.ageRestrictedOrDisabled": "Age Restricted and/or Disability",
    "filters.options.rentBasedOnIncome": "Rent Based on Income",
    "filters.options.notRentBasedOnIncome": "Not Rent Based on Income",
    "filters.options.belowMarketRent": "Fixed Below Market Rent",
    "filters.options.verified": "Verified",
    "filters.options.petFriendly": "Pet Friendly",
    "filters.bedrooms.title": "Bedrooms",
    "filters.bedrooms.srTitle": "Bedrooms filter",
    "filters.bedrooms.anyBedrooms": "Any",
    "filters.bedrooms.bedroomSize": "Bedroom Size",
    "filters.bedrooms.accessibilityFilters": "Accessibility Features",
    "filters.availability.all": "Show Everything",
    "filters.availability.title": "Availability",
    "filters.availability.srTitle": "Availability filters",
    "filters.availability.lotteries": "Lotteries",
    "filters.availability.openWaitlist": "Waitlist Open",
    "filters.availability.closedWaitlist": "Waitlist Closed",
    "filters.availability.shortWaitlist": "Short Waitlist",
    "filters.availability.fcfs": "First Come, First Served",
    "filters.eligibility.title": "Eligibility",
    "filters.eligibility.srTitle": "Eligibility",
    "filters.affordability.title": "Affordability",
    "filters.affordability.srTitle": "Affordability",
    "filters.affordability.rentHeader": "Rent",
    "filters.eligibility.restrictionsHeader": "Age Restrictions",
    "filters.eligibility.noneSelected": "Any Restrictions",
    "filters.eligibility.anyAge": "Any Age",
    "filters.eligibility.ageRestricted60plus": "60",
    "filters.eligibility.ageRestricted55plus": "55",
    "filters.eligibility.ageRestricted58plus": "58",
    "filters.eligibility.ageRestricted62plus": "62",
    "filters.more.title": "More",
    "filters.more.srTitle": "More filters",
    "filters.more.amenitiesHeader": "Amenities",
    "filters.more.accessibilityHeader": "Property Accessibility",
    "filters.more.other": "Other",
    "filters.more.noneSelected": "None Selected",
    "filters.location.title": "Search Location",
    "filters.anyOption": "Any {filterLabel}",

    // External Link Modal
    "externalLinkModal.title": "You are leaving this site",
    "externalLinkModal.propertyWebsite":
      "You are leaving the Housing Navigator search tool. Click to go to the property's website and learn more.",
    "externalLinkModal.lotteryWebsite":
      "You are leaving the Housing Navigator search tool. Click to go to the property's lottery website and learn more.",
    "externalLinkModal.application":
      "You are leaving the Housing Navigator search tool. Click to go to the application on the property's website.",
    "externalLinkModal.applicationChamp":
      "You are leaving the Housing Navigator search tool. Apply on the state of Massachusetts's application system, CHAMP.",
    "externalLinkModal.other":
      "You are leaving the Housing Navigator search tool. Click to go to {description}.",
    "externalLinkModal.goBackText": "Cancel",
    "externalLinkModal.continueText": "Open this link in a new tab",
    "externalLinkModal.defaultPropertyName": "this property",
    "externalLinkModal.defaultDescription": "an external website",

    // Pre-release banner
    "preReleaseBanner.beta.firstLine": "This website is not yet public.",
    "preReleaseBanner.beta.secondLine":
      "Listings are simulated and should not be used to find housing. Thank you for checking it out during our beta testing.",

    "maintenanceBanner.text":
      "The Housing Navigator Massachusetts search tool will be temporarily unavailable due to planned maintenance starting at 6 pm on June 30th.",

    "accessibilityFiltersBanner.text":
      "We have improved how you narrow your search, including news ways to find accessible units under the bedrooms filter",

    "print.applyAt": "Next Steps",

    // PM Stuff
    "pm.recordCount": "{count, number, ::precision-integer}",

    // Authentication
    "auth.loginFailed": "Login Failed",
    "auth.otpAttemptFailed":
      "Code Verification Failed. Please wait a few seconds and try again with a new code.",
    "auth.accountLocked":
      "Account locked because of too many failed login attempts. Check your email to unlock your account.",
  },
  es: {
    "building.hasDeeplyAffordable": "*Rent Based on Income",
    "listing.unitDetails": "*Unit Details",
    "listing.noUnitsAvailable": "*No units available",
    "listing.applicationRequirements": "*Application Requirements",
    "listing.waitlistOpen": "abierto",
    "listing.waitlistClosed": "cerrado",
    "listing.moveInRequirements": "*Move-in Requirements",
    "listing.attachments": "*Attachments",
    "listing.expiresOn": "*Expires: {date}",
    "listing.waitlist": "*Waitlist",
    "listing.firstComeFirstServe": "*First Come, First Served",
    "listing.lottery": "*Lottery",
    "listing.visitWebsite": "*Visit Website",
    "listing.ageRestriction": "*Age Restrictions",
    "listing.ageRestrictionAll": "*All household members must be {ageRestriction}",
    "listing.ageRestrictionOne": "*At least one household member must be {ageRestriction}",
    "listing.ageRestrictionDisabled": "*or disabled",
    "listing.downloadAttachments": "*Download Attachments",
    "listing.rentBasedOnIncome": "*Rent Based on income",
    "listing.rentBasedOnIncomeMessage":
      "*This property includes units where rent is set to a percentage of the household income, for example 30% of monthly income.",
    "listing.minIncomePerYear": "*Minimum Income per year",
    "listing.unitPropertyInfo": "*Unit/Property Info",
    "listing.amenities": "*Amenities",
    "listing.utilities": "*Utilities",
    "listing.accessibility": "*Accessibility",
    "listing.petPolicy": "*Pet Policy",
    "listing.location": "*Location",
    "listing.contactInfo": "*Contact Info",
    "listing.numberOfBedrooms": "*Number of Bedrooms",
    "listing.mapMissing": "*Map Missing",
    "footer.termsOfService": "*Terms of Service",
    "footer.privacyPolicy": "*Privacy Policy",
    "footer.subscribeToOurNewsletter": "*Subscribe to our newsletter",
    "dialog.close": "*Close",
    "unitDetailsTable.size": "*Size",
    "unitDetailsTable.numberAvailable": "*Number Available",
    "unitDetailsTable.bathrooms": "*Bathrooms",
    "unitDetailsTable.accessibility": "*Accessibility",
    "unitDetailsTable.rentInfo": "*Rent Info",
    "unitDetailsTable.moreInfo": "*More info",
    "unitDetailsTable.lessDetails": "*Less details",
    "unitDetailsTable.moreDetails": "*More details",
    "listingCard.viewListing": "*View Listing",
  },
};
