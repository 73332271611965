import React, { ReactNode } from "react";
import { PrimitiveType } from "intl-messageformat";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { LocalizationKey } from "./types";

type Values = Record<string, PrimitiveType | ReactNode>;
export type MessageFormatter = {
  (id: LocalizationKey, values?: Values): string;
};

export function useFormatMessage() {
  const intl = useIntl();
  return useCallback<MessageFormatter>(
    (id, values) =>
      // @ts-ignore - This is showing the <b> tag formatting as incorrect type, but it does work
      intl.formatMessage({ id }, { ...values, b: (chunks) => <strong>{chunks}</strong> }),
    [intl],
  );
}
